<template>
  <l-table-container>
    <template v-slot:search>
      <a-form ref="formRef" :model="search.data" :labelCol="{ style: { width: '110px' } }">
        <l-search-container @search="onSearch" @reset="onReset">
          <a-col v-bind="searchCol">
            <a-row type="flex" align="top" :gutter="10">
              <a-col :flex="1">
                <a-form-item label="变化时间" name="startTime">
                  <a-date-picker v-model:value="search.data.startTime" :disabled-date="disabledDate" :allowClear="false" inputReadOnly placeholder="开始时间" style="width: 100%" value-format="YYYY-MM-DD" />
                </a-form-item>
              </a-col>

              <a-col> <div style="margin-top: 5px">~</div> </a-col>

              <a-col :flex="1">
                <a-form-item label="" name="endTime">
                  <a-date-picker v-model:value="search.data.endTime" :disabled-date="disabledDate" :allowClear="false" inputReadOnly placeholder="结束时间" style="width: 100%" value-format="YYYY-MM-DD" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="变化类型" name="changeType">
              <a-select v-model:value="search.data.changeType" placeholder="请选择变化类型" allow-clear :options="SafeHpLogsTypes" filter-option show-search option-filter-prop="label">
                <!-- <a-select-option :value="item.value" v-for="item in clubHpChangeTypes" :key="item.value">{{ item.label }}</a-select-option> -->
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="成员游戏ID" name="memberMaskId">
              <a-input v-model:value="search.data.memberMaskId" placeholder="请输入游戏ID" allowClear> </a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="成员userId" name="memberId">
              <a-input v-model:value="search.data.memberId" placeholder="请输入userId" allowClear> </a-input>
            </a-form-item>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="俱乐部ID" name="clubId">
              <a-input v-model:value="search.data.clubId" placeholder="请输入俱乐部ID" allowClear> </a-input>
            </a-form-item>
          </a-col>
        </l-search-container>
      </a-form>
    </template>

    <l-table @reload="onSearch" :columns="columns" :data-source="data" :pagination="pagination" @change="handleTableChange" row-key="index" :loading="loading">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'changeHp'">
          <span v-show="record.changeHp >= 0" class="l-font-success l-bold"> +{{ record.changeHp }} </span>
          <span v-show="record.changeHp < 0" class="l-font-danger l-bold"> {{ record.changeHp }} </span>
        </template>

        <template v-if="column.dataIndex === 'changeType'">{{ getLabelByValue(SafeHpLogsTypes, record.changeType) }}</template>

        <template v-if="column.dataIndex === 'memberIcon'">
          <a-avatar :src="record.memberIcon" size="30" />
        </template>

        <template v-if="column.dataIndex === 'relationIcon'">
          <a-avatar :src="record.relationIcon" size="30" />
        </template>
      </template>
    </l-table>
  </l-table-container>
</template>

<script lang="ts">
import http from "@/common/http";
import { getLabelByValue, SafeHpLogsTypes, gameTypes } from "@/common/head";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import { dateIsBetween, getPageOffset } from "@/common/utils";
import { Dayjs } from "dayjs";
import { PaginationProps } from "ant-design-vue/es/pagination";

const columns = [
  // {
  //   title: "序号",
  //   width: 100,
  //   dataIndex: "index",
  //   fixed: "left",
  // },

  {
    title: "俱乐部ID",
    width: 100,
    dataIndex: "clubId",
    ellipsis: true,
  },

  {
    title: "俱乐部名称",
    width: 180,
    dataIndex: "clubName",
    ellipsis: true,
  },

  {
    title: "变化类型",
    width: 140,
    dataIndex: "changeType",
    ellipsis: true,
  },

  {
    title: "变化前",
    width: 140,
    dataIndex: "beforeHp",
    ellipsis: true,
  },

  {
    title: "变化的体力",
    width: 140,
    dataIndex: "changeHp",
    ellipsis: true,
  },

  {
    title: "变化后",
    width: 140,
    dataIndex: "afterHp",
    ellipsis: true,
  },

  {
    title: "成员userID",
    width: 150,
    dataIndex: "memberId",
    ellipsis: true,
  },

  {
    title: "成员游戏ID",
    width: 150,
    dataIndex: "memberMaskId",
    ellipsis: true,
  },

  {
    title: "成员昵称",
    width: 150,
    dataIndex: "memberName",
    ellipsis: true,
  },

  {
    title: "成员头像",
    width: 100,
    dataIndex: "memberIcon",
    ellipsis: true,
  },

  {
    title: "关联者userID",
    width: 150,
    dataIndex: "relationId",
    ellipsis: true,
  },

  {
    title: "关联者游戏ID",
    width: 150,
    dataIndex: "relationMaskId",
    ellipsis: true,
  },

  {
    title: "关联者昵称",
    width: 150,
    dataIndex: "relationName",
    ellipsis: true,
  },

  {
    title: "关联者头像",
    width: 150,
    dataIndex: "relationIcon",
    ellipsis: true,
  },

  {
    title: "备注",
    width: 160,
    dataIndex: "remark",
    // fixed: "right",
  },

  {
    title: "变化时间",
    width: 180,
    dataIndex: "createTime",
    fixed: "right",
  },

  // {
  //   title: "操作",
  //   key: "operation",
  //   dataIndex: "operation",
  //   fixed: "right",
  //   width: 100,
  //   align: "center",
  //   slots: { customRender: "action" },
  // },
];

export default defineComponent({
  name: "us_club_member_safe_log",
  components: {},
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        memberMaskId: "",
        memberId: "",
        startTime: "",
        endTime: "",
        clubId: "",
        changeType: undefined,
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const formRef = ref();

    //开始结束时间
    const validTimeRange = reactive({
      minDate: "",
      maxDate: "",
    });

    //获取不可选择的时间
    const disabledDate = (currentDate: Dayjs) => {
      return !dateIsBetween(validTimeRange.minDate, validTimeRange.maxDate, currentDate);
    };

    //首次进入，获取可选的日期范围
    const getTime = async () => {
      loading.value = true;
      const { data } = await http.get("us_club_member_safe_log/time");
      validTimeRange.minDate = data.minDate;
      validTimeRange.maxDate = data.maxDate;
      search.data.startTime = data.maxDate;
      search.data.endTime = data.maxDate;

      onSearch();
    };

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("us_club_member_safe_log", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
      });

      let offset = getPageOffset(pagination.current!, pagination.pageSize!);
      data.value = result.data.data.map((item: any) => {
        item.index = ++offset;
        return item;
      });

      pagination.total = result.data.count;

      loading.value = false;
    };

    //监听页码切换
    const handleTableChange = (pag: PaginationProps) => {
      pagination.current = pag?.current;
      pagination.pageSize = pag?.pageSize;
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      search.data.startTime = validTimeRange.maxDate;
      search.data.endTime = validTimeRange.maxDate;
      onSearch();
    };

    onMounted(() => {
      getTime();
    });

    return {
      data,
      columns,
      search,
      pagination,
      formRef,
      loading,
      SafeHpLogsTypes,
      gameTypes,
      handleTableChange,
      onSearch,
      onReset,
      searchCol,
      disabledDate,
      getLabelByValue,
    };
  },
});
</script>
